export const ObfuscatorEngine = function () {
  const obfuscators = [];

  return {
    withDefautls: function () {
      return this.withObfuscatorFor("AssetManagementPortfolio", obfuscatePortfolio)
        .withObfuscatorFor("AssetManagementProperty", obfuscateProperty)
        .withObfuscatorFor("AssetManagementTenancy", obfuscateTenancy)
        .withObfuscatorFor("AssetManagementTenant", obfuscateTenant)
        .withObfuscatorFor("AssetManagementFinancialRecord", obfuscateFinancialRecord)
        .withObfuscatorFor("AssetManagementSale", obfuscateSale)
        .withObfuscatorFor("AssetManagementValuation", obfuscateValuation)
        .withObfuscatorFor("FinancialSnapshotAccountSpecification", obfuscateAccountSpecification)
        .withObfuscatorFor("FinancialSnapshotPortfolio", obfuscateFinancialSnapshotPortfolio)
        .withObfuscatorFor("FinancialSnapshotProperty", obfuscateFinancialSnapshotProperty)
        .withObfuscatorFor("FinancialSnapshotAccountPortfolioSpecification", obfuscateAccountPortfolioSpecification)
        .withObfuscatorFor("IdleTenancyMetricsPortfolio", obfuscateIdleTenancyMetricsPortfolio)
        .withObfuscatorFor("IdleTenancyMetricsProperty", obfuscateIdleTenancyMetricsProperty)
        .withObfuscatorFor("AreaOpexProperty", obfuscateAreaOpexProperty)
        .withObfuscatorFor("PlannedRentsMetricsPortfolio", obfuscatePlannedRent)
        .withObfuscatorFor("PlannedRentsMetricsProperty", obfuscatePlannedRent)
        .withObfuscatorFor("PlannedRentsMetricsTenancy", obfuscatePlannedRent)
        .withObfuscatorFor("PlannedRentsMetricsTenant", obfuscatePlannedRent)
        .withObfuscatorFor("FinancialSnapshotAggregatedValuePortfolio", obfuscateFinancialSnapshotPortfolio)
        .withObfuscatorFor("FinancialSnapshotAggregatedValueProperty", obfuscateFinancialSnapshotProperty)
        .withObfuscatorFor("AssetManagementResponsible", obfuscateUser)
        .withObfuscatorFor("User", obfuscateUser);
    },

    withObfuscatorFor: function (type, obfuscator) {
      obfuscators.push(DataObfuscator(type, obfuscator));
      return this;
    },

    obfuscate: function (data) {
      obfuscators.forEach((obfuscator) => {
        data = { ...data, ...obfuscator.obfuscate(data) };
      });
    },
  };
};

export const DataObfuscator = function (type, obfuscator) {
  return {
    obfuscate: function (value) {
      var objs = findAllObjectsByTypeNameRecursively(value, type);

      objs.forEach((obj) => {
        obfuscator(obj);
      });
    },
  };
};

const findAllObjectsByTypeNameRecursively = function (inputObj, typeName) {
  const foundObjects = [];

  function searchObject(obj) {
    if (obj != null) {
      if (typeof obj === "object") {
        if (obj.__typename === typeName) {
          foundObjects.push(obj);
        }

        Object.entries(obj).forEach(([key, value]) => {
          searchObject(value);
        });
      } else if (Array.isArray(obj)) {
        obj.forEach((item) => {
          searchObject(item);
        });
      }
    }
  }

  searchObject(inputObj);
  return foundObjects;
};

function obfuscateProperty(property) {
  const [number1, number2] = generateIndexesFromString(property.id, streetNames.length - 1);
  const streetNumber = number1 + 1;

  property.name = `${streetNames[number1]} ${streetNumber}, ${cityNames[number2]}`;

  return property;
}

function obfuscateTenancy(tenancy) {
  tenancy.name = "Lejemål";

  return tenancy;
}

function obfuscateTenant(tenant) {
  const [number1, number2] = generateIndexesFromString(tenant.id, initials.length - 1);

  tenant.name = `${initials[number1]} ${surnames[number2]}`;
  tenant.rentPerAnnum = generateObfuscatedNumber(tenant.rentPerAnnum, 0.2);
  tenant.rentPerAreaPerAnnum = generateObfuscatedNumber(tenant.rentPerAreaPerAnnum, 0.2);

  return tenant;
}

function obfuscateFinancialRecord(financialRecord) {
  financialRecord.actualCostWithVat = generateObfuscatedNumber(financialRecord.actualCostWithVat, 0.2);
  financialRecord.estimatedCost = generateObfuscatedNumber(financialRecord.estimatedCost, 0.2);

  return financialRecord;
}

function obfuscateSale(sale) {
  sale.actualSalesPrice = generateObfuscatedNumber(sale.actualSalesPrice, 0.2);
  sale.bankChargesWithInterest = generateObfuscatedNumber(sale.bankChargesWithInterest, 0.2);
  sale.agencyFeeWithVatDocCharges = generateObfuscatedNumber(sale.agencyFeeWithVatDocCharges, 0.2);
  sale.bondRepayment = generateObfuscatedNumber(sale.bondRepayment, 0.2);
  sale.lenderBrokerageFee = generateObfuscatedNumber(sale.lenderBrokerageFee, 0.2);
  sale.lenderCharge = generateObfuscatedNumber(sale.lenderCharge, 0.2);
  sale.mortgageInterest = generateObfuscatedNumber(sale.mortgageInterest, 0.2);
  sale.mortgageMargin = generateObfuscatedNumber(sale.mortgageMargin, 0.2);
  sale.repaymentActual = generateObfuscatedNumber(sale.repaymentActual, 0.2);

  return sale;
}

function obfuscateValuation(valuation) {
  valuation.valuationAmount = generateObfuscatedNumber(valuation.valuationAmount, 0.2);
  valuation.askingPrice = generateObfuscatedNumber(valuation.askingPrice, 0.2);
  valuation.businessPlanPrice = generateObfuscatedNumber(valuation.businessPlanPrice, 0.2);
  valuation.minSalesPrice = generateObfuscatedNumber(valuation.minSalesPrice, 0.2);

  return valuation;
}

function obfuscateAccountSpecification(accountSpecification) {
  accountSpecification.totalCostWithVat = generateObfuscatedNumber(accountSpecification.totalCostWithVat, 0.2);

  return accountSpecification;
}

function obfuscatePortfolio(portfolio) {
  const [number1, _] = generateIndexesFromString(portfolio.name, portfolioNames.length - 1);

  portfolio.name = portfolioNames[number1];

  return portfolio;
}

function obfuscateFinancialSnapshotPortfolio(financialSnapshotPortfolio) {
  financialSnapshotPortfolio.change = generateObfuscatedNumber(financialSnapshotPortfolio.change, 0.2);
  financialSnapshotPortfolio.changePercentage = generateObfuscatedNumber(financialSnapshotPortfolio.changePercentage, 0.2);
  financialSnapshotPortfolio.totalCostWithVat = generateObfuscatedNumber(financialSnapshotPortfolio.totalCostWithVat, 0.2);

  return financialSnapshotPortfolio;
}

function obfuscateFinancialSnapshotProperty(financialSnapshotProperty) {
  financialSnapshotProperty.change = generateObfuscatedNumber(financialSnapshotProperty.changePercentage, 0.2);
  financialSnapshotProperty.totalCostWithVat = generateObfuscatedNumber(financialSnapshotProperty.totalCostWithVat, 0.2);
  financialSnapshotProperty.totalCostWithVat = generateObfuscatedNumber(financialSnapshotProperty.totalCostWithVat, 0.2);

  return financialSnapshotProperty;
}

function obfuscateAccountPortfolioSpecification(accountPortfolioSpecification) {
  accountPortfolioSpecification.change = generateObfuscatedNumber(accountPortfolioSpecification.change, 0.2);
  accountPortfolioSpecification.changePercentage = generateObfuscatedNumber(accountPortfolioSpecification.changePercentage, 0.2);
  accountPortfolioSpecification.totalCostWithVat = generateObfuscatedNumber(accountPortfolioSpecification.totalCostWithVat, 0.2);

  return accountPortfolioSpecification;
}

function obfuscateIdleTenancyMetricsPortfolio(idleTenancyMetricsPortfolio) {
  idleTenancyMetricsPortfolio.change = generateObfuscatedNumber(idleTenancyMetricsPortfolio.change, 0.2);
  idleTenancyMetricsPortfolio.changePercentage = generateObfuscatedNumber(idleTenancyMetricsPortfolio.changePercentage, 0.2);
  idleTenancyMetricsPortfolio.totalCostWithVat = generateObfuscatedNumber(idleTenancyMetricsPortfolio.totalCostWithVat, 0.2);

  return idleTenancyMetricsPortfolio;
}

function obfuscateIdleTenancyMetricsProperty(idleTenancyMetricsProperty) {
  idleTenancyMetricsProperty.totalCostWithVat = generateObfuscatedNumber(idleTenancyMetricsProperty.totalCostWithVat, 0.2);

  return idleTenancyMetricsProperty;
}

function obfuscatePlannedRent(plannedRent) {
  plannedRent.amount = generateObfuscatedNumber(plannedRent.amount, 0.2);

  return plannedRent;
}

function obfuscateAreaOpexProperty(areaOpexProperty) {
  areaOpexProperty.totalCostPerArea = generateObfuscatedNumber(areaOpexProperty.totalCostPerArea, 0.2);
  areaOpexProperty.totalCostWithVat = generateObfuscatedNumber(areaOpexProperty.totalCostWithVat, 0.2);

  return areaOpexProperty;
}

function obfuscateUser(user) {
  const [number1, number2] = generateIndexesFromString(user.id, initials.length - 1);

  user.name = `${initials[number1]} ${surnames[number2]}`;

  return user;
}


function generateIndexesFromString(input, upperBound) {
  if (input == null) {
    return [0, 0];
  }

  const hashValue = hashString(input, upperBound);
  const number1 = hashValue;

  // To ensure the second number is different from the first one,
  // we'll add a constant value before calculating the second hash.
  const number2 = (hashValue + 7) % (upperBound + 1);

  return [number1, number2];
}

function generateObfuscatedNumber(inputNumber, percentage) {
  const range = inputNumber * percentage;
  const offset = hashNumber(inputNumber, range);

  if (offset % 2 == 0) {
    return inputNumber - offset;
  } else {
    return inputNumber + offset;
  }
}

function hashNumber(inputNumber, range) {
  // A simple hash function that ensures consistent results
  let hash = (inputNumber * 37) % 1000003; // Using a prime number as the multiplier

  // Map the hash to the desired range
  return (hash % (range * 2 + 1)) - range;
}

function hashString(input, upperBound) {
  let hash = 0;

  for (let i = 0; i < input.length; i++) {
    hash = (hash + input.charCodeAt(i)) % (upperBound + 1);
  }

  return hash;
}

// List of randomly generated Danish street names
const streetNames = [
  "Kongensgade",
  "Vestergade",
  "Østergade",
  "Nørregade",
  "Søndergade",
  "Algade",
  "Adelgade",
  "Bredgade",
  "Lille Torv",
  "Store Torv",
  "Grønnegade",
  "Klostergade",
  "Klostertorvet",
  "Klostergårdsvej",
  "Klostergade",
];

// List of randomly generated Danish city names
const cityNames = [
  "København",
  "Aarhus",
  "Odense",
  "Aalborg",
  "Esbjerg",
  "Randers",
  "Kolding",
  "Horsens",
  "Vejle",
  "Roskilde",
  "Herning",
  "Hørsholm",
  "Helsingør",
];

// List of randomly generated initials
const initials = ["A.", "B.", "C.", "D.", "E.", "F.", "G.", "H.", "I.", "J.", "K.", "L.", "M.", "N.", "O."];

// List of randomly generated Danish surnames
const surnames = [
  "Jensen",
  "Nielsen",
  "Hansen",
  "Pedersen",
  "Andersen",
  "Christensen",
  "Larsen",
  "Sørensen",
  "Rasmussen",
  "Jørgensen",
  "Petersen",
  "Madsen",
  "Kristensen",
  "Olsen",
  "Thomsen",
  "Christiansen",
  "Poulsen",
  "Johansen",
  "Møller",
  "Mortensen",
  "Knudsen",
  "Jakobsen",
  "Jespersen",
  "Johannsen",
  "Mikkelsen",
  "Frederiksen",
  "Madsen",
  "Mortensen",
  "Knudsen",
  "Jakobsen",
  "Jespersen",
  "Johannsen",
  "Mikkelsen",
  "Frederiksen",
];

// List of randomly generated portfolio names
const portfolioNames = [
  "Luna",
  "Elsa",
  "Oliver",
  "Cora",
  "Zara",
  "Liam",
  "Aria",
  "Felix",
  "Isabella",
  "Milo",
  "Sophie",
  "Lucas",
  "Astrid",
  "Noah",
  "Ava",
  "Ethan",
  "Amelia",
  "Leo",
  "Mia",
  "Oscar",
  "Ella",
  "Sebastian",
  "Grace",
  "Jackson",
  "Lily",
  "Henry",
  "Charlotte",
  "Theo",
  "Scarlett",
  "Samuel",
  "Hazel",
  "Alexander",
  "Nova",
  "Benjamin",
  "Stella",
  "Caleb",
  "Ruby",
  "Max",
  "Zoe",
  "Elijah",
  "Chloe",
  "Jack",
  "Ivy",
  "William",
  "Aurora",
  "James",
  "Penelope",
  "Logan",
  "Layla",
  "Daniel",
  "Nora",
  "David",
  "Emilia",
  "Michael",
  "Victoria",
  "Lukas",
  "Sophia",
  "Ben",
  "Emma",
  "Finn",
  "Evelyn",
  "Jasper",
  "Maya",
  "Levi",
  "Harper",
  "Gabriel",
  "Madeline",
  "Owen",
  "Eleanor",
  "Carter",
  "Avery",
  "Isaac",
  "Grace",
  "Matthew",
  "Hannah",
  "Nathan",
  "Sofia",
  "Dylan",
  "Lucy",
  "Evan",
  "Lillian",
  "Andrew",
  "Anna",
  "Joshua",
  "Zara",
];
